.card.card-body {
    border-radius: 0;
    text-transform: uppercase;
    color: #0d428d;
    font-weight: bold;
    width: 350px;
    height: 128px
}

.card-deck a {
    margin-left: auto;
    margin-right: auto;
    font-size: 19px;
}

@media (min-width: 576px) {
    .card-deck .card{
        margin-bottom: 15px
    }

}
.body-content {
    min-height: calc(100vh - 130px);
    padding-top: 20px;
}

.footer {
    background-color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    clear:both;
}

footer {
    height: 110px;
    padding-top: 20px;
    margin-top: 20px;
    padding-bottom: 20px;
}

    footer .left {
        float: left;
    }

.solez-logo {
    max-height: 80px;
}

.cdv-logo {
    display: block;
    max-height: 50px;
    margin-left: auto;
    margin-right: auto;
}

.tree-logo {
    max-height: 50px;
    float: right;
}

.home-button {
    position: fixed;
    top: 5px;
    left: 5px;
    color: #ffffff;
    padding: 6px 9px;
    margin-left: auto;
    margin-right: auto;
    z-index: 100
}
.language-text {
    margin-right: 20px;
    color: #ffffff;
}

.currency-selector-container {
    margin: 15px;
}
.calculator-body {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.main-card {
    margin: 20px 10px;
    color: #000000;
}

.card-header {
    background-color: #0d428d;
    color: #ffffff;
}

    .card, .card-header, .card-header:first-child, card-body {
        border: none;
        border-radius: 0;
    }

.phase-img {
    width: 130px;
    float: left;
    margin-bottom: 25px
}

.phase-list {
    margin-left: 120px
}

h2 {
    clear: both;
}

li {
    font-size: 19px
}
body{font-family:'Open Sans',sans-serif;color:#fff;background-color:#159961}h1{color:#fff;text-transform:uppercase;text-align:center;font-size:3.5rem}.btn-primary{background-color:#0d428d;border-color:#0d428d}p{font-size:19px;text-align:justify}
