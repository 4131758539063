.calculator-body {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.main-card {
    margin: 20px 10px;
    color: #000000;
}

.card-header {
    background-color: #0d428d;
    color: #ffffff;
}

    .card, .card-header, .card-header:first-child, card-body {
        border: none;
        border-radius: 0;
    }
