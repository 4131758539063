.phase-img {
    width: 130px;
    float: left;
    margin-bottom: 25px
}

.phase-list {
    margin-left: 120px
}

h2 {
    clear: both;
}

li {
    font-size: 19px
}