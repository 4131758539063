.body-content {
    min-height: calc(100vh - 130px);
    padding-top: 20px;
}

.footer {
    background-color: #ffffff;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    clear:both;
}

footer {
    height: 110px;
    padding-top: 20px;
    margin-top: 20px;
    padding-bottom: 20px;
}

    footer .left {
        float: left;
    }

.solez-logo {
    max-height: 80px;
}

.cdv-logo {
    display: block;
    max-height: 50px;
    margin-left: auto;
    margin-right: auto;
}

.tree-logo {
    max-height: 50px;
    float: right;
}

.home-button {
    position: fixed;
    top: 5px;
    left: 5px;
    color: #ffffff;
    padding: 6px 9px;
    margin-left: auto;
    margin-right: auto;
    z-index: 100
}