.card.card-body {
    border-radius: 0;
    text-transform: uppercase;
    color: #0d428d;
    font-weight: bold;
    width: 350px;
    height: 128px
}

.card-deck a {
    margin-left: auto;
    margin-right: auto;
    font-size: 19px;
}

@media (min-width: 576px) {
    .card-deck .card{
        margin-bottom: 15px
    }

}